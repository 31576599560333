// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../res/img/svg/check-dark.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../res/img/svg/error-red.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileDeleteButtonSuccess{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 0/16px 16px;margin:0;padding-left:20px;white-space:nowrap}.profileDeleteButtonError{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat 0 4px/16px 16px;color:var(--red-60);padding-left:24px;word-break:break-word}.profileDeletePanel{max-width:80vw;width:500px}`, "",{"version":3,"sources":["webpack://./src/components/app/ProfileDeleteButton.css"],"names":[],"mappings":"AAIA,4BAIE,wEACM,CAFN,QAAS,CADT,iBAAkB,CAIlB,kBACF,CAEA,0BAKE,4EACM,CACN,mBAAoB,CALpB,iBAAkB,CAMlB,qBACF,CAEA,oBAEE,cAAe,CADf,WAEF","sourcesContent":["/* This Source Code Form is subject to the terms of the Mozilla Public\n * License, v. 2.0. If a copy of the MPL was not distributed with this\n * file, You can obtain one at http://mozilla.org/MPL/2.0/. */\n\n.profileDeleteButtonSuccess {\n  /* Note: 20px is: 16px (icon width) + 4px (distance from the text) */\n  padding-left: 20px;\n  margin: 0;\n  background: url(../../../res/img/svg/check-dark.svg) no-repeat left / 16px\n    16px;\n  white-space: nowrap;\n}\n\n.profileDeleteButtonError {\n  /* Note: 24px is: 16px (icon width) + 4px (icon padding) + 4px (distance from the text) */\n  padding-left: 24px;\n\n  /* The icon is 4px below the top */\n  background: url(../../../res/img/svg/error-red.svg) no-repeat 0 4px / 16px\n    16px;\n  color: var(--red-60);\n  word-break: break-word;\n}\n\n.profileDeletePanel {\n  width: 500px;\n  max-width: 80vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
