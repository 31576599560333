// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("firefox-profiler-res/img/svg/link-dark-12.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuButtonsPermalinkButtonButton:before{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.menuButtonsPermalinkPanel .arrowPanelContent{padding:4px}.menuButtonsPermalinkTextField{font-family:ui-monospace,monospace;font-size:12px;height:19px;width:20em}`, "",{"version":3,"sources":["webpack://./src/components/app/MenuButtons/Permalink.css"],"names":[],"mappings":"AAMA,yCACE,wDACF,CAEA,8CAEE,WACF,CAEA,+BAKE,kCAAoC,CACpC,cAAe,CAJf,WAAY,CADZ,UAMF","sourcesContent":["/* This Source Code Form is subject to the terms of the Mozilla Public\n * License, v. 2.0. If a copy of the MPL was not distributed with this\n * file, You can obtain one at http://mozilla.org/MPL/2.0/. */\n\n/* This background image builds on the generic styles defined in\n * MenuButtons/index.css when using an icon. */\n.menuButtonsPermalinkButtonButton::before {\n  background-image: url(firefox-profiler-res/img/svg/link-dark-12.svg);\n}\n\n.menuButtonsPermalinkPanel .arrowPanelContent {\n  /* Override the default padding for ArrowPanel */\n  padding: 4px;\n}\n\n.menuButtonsPermalinkTextField {\n  width: 20em;\n  height: 19px;\n\n  /* Use a monospace font so that I and l can be differentiated */\n  font-family: ui-monospace, monospace;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
