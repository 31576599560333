// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("firefox-profiler-res/img/svg/edit-name-profiler.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileNameInput{border:none;border-radius:1px;color:var(--grey-60);font:inherit;font-weight:700;height:17px;line-height:17px;margin:4px;padding:0 6px}.profileNameButton{font-weight:700;margin-inline-end:5px;overflow:hidden;padding:0 9px;text-overflow:ellipsis;white-space:nowrap}.profileNameButton:before{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.profileNameInput:focus-visible{box-shadow:0 0 0 2px var(--blue-50),0 0 0 4px var(--blue-50-a30);outline:0}`, "",{"version":3,"sources":["webpack://./src/components/app/ProfileName.css"],"names":[],"mappings":"AAIA,kBAGE,WAAY,CACZ,iBAAkB,CAElB,oBAAqB,CACrB,YAAa,CACb,eAAgB,CAPhB,WAAY,CAQZ,gBAAiB,CAJjB,UAAW,CAHX,aAQF,CAEA,mBAGE,eAAgB,CAChB,qBAAsB,CAHtB,eAAgB,CAChB,aAAc,CAGd,sBAAuB,CACvB,kBACF,CAEA,0BACE,wDACF,CAIA,gCACE,gEAE8B,CAC9B,SACF","sourcesContent":["/* this source code form is subject to the terms of the mozilla public\n * license, v. 2.0. if a copy of the mpl was not distributed with this\n * file, you can obtain one at http://mozilla.org/mpl/2.0/. */\n\n.profileNameInput {\n  height: 17px;\n  padding: 0 6px;\n  border: none;\n  border-radius: 1px;\n  margin: 4px;\n  color: var(--grey-60);\n  font: inherit;\n  font-weight: 700;\n  line-height: 17px;\n}\n\n.profileNameButton {\n  overflow: hidden;\n  padding: 0 9px;\n  font-weight: 700;\n  margin-inline-end: 5px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.profileNameButton::before {\n  background-image: url(firefox-profiler-res/img/svg/edit-name-profiler.svg);\n}\n\n/* Using the style for links rather than for normal inputs, because we don't\n * want to trigger a border that would move things around. */\n.profileNameInput:focus-visible {\n  box-shadow:\n    0 0 0 2px var(--blue-50),\n    0 0 0 4px var(--blue-50-a30);\n  outline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
