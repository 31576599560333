// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../res/img/svg/pane-collapse.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../res/img/svg/pane-expand.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Details{display:flex;flex:auto;flex-direction:column}.Details-top-bar{align-items:center;background:var(--grey-10);border:solid var(--grey-30);border-width:1px 0;display:flex;flex-flow:row nowrap;justify-content:space-between}.sidebar-open-close-button{background-position:50%;background-repeat:no-repeat;flex:none;height:24px;margin:0 4px;width:24px}.sidebar-open-close-button-isopen{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.sidebar-open-close-button-isclosed{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}`, "",{"version":3,"sources":["webpack://./src/components/app/Details.css"],"names":[],"mappings":"AAAA,SACE,YAAa,CACb,SAAU,CACV,qBACF,CAGA,iBAGE,kBAAmB,CAInB,yBAA0B,CAF1B,2BAA4B,CAC5B,kBAAmB,CALnB,YAAa,CACb,oBAAqB,CAErB,6BAIF,CAEA,2BAKE,uBAA2B,CAC3B,2BAA4B,CAH5B,SAAU,CADV,WAAY,CAEZ,YAAa,CAHb,UAMF,CAEA,kCACE,wDACF,CAEA,oCACE,wDACF","sourcesContent":[".Details {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n}\n\n/* contains the tabbar and, if present, the sidebar button */\n.Details-top-bar {\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  justify-content: space-between; /* This pushes the sidebar button to the right */\n  border: solid var(--grey-30);\n  border-width: 1px 0;\n  background: var(--grey-10);\n}\n\n.sidebar-open-close-button {\n  width: 24px;\n  height: 24px;\n  flex: none;\n  margin: 0 4px;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.sidebar-open-close-button-isopen {\n  background-image: url(../../../res/img/svg/pane-collapse.svg);\n}\n\n.sidebar-open-close-button-isclosed {\n  background-image: url(../../../res/img/svg/pane-expand.svg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
